<template>
  <div>
    <apexchart
      type="line"
      :height="height"
      :width="width"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
  
  <script>
 import VueApexCharts from 'vue-apexcharts';
  
  export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: {
      height: {
        type: [String, Number],
        default() {
          return 400;
        },
      },
      width: {
        type: [String, Number],
        default() {
          return 500;
        },
      }, 
    },
    data() {
      return {
        series: [
          {
            name: "Data",
            data: [30, 40, 35, 50], // Your data points
          },
        ],
        chartOptions: {
          chart: {
            width: '100%',
            type: "line",
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
            width: 2, // Set the width of the line
            colors: ["#d3bd95"], // Change the line color to #FEF5E5
          },
          markers: {
            size: 5, // Control the size of the markers
            colors: ["#FFAE20"], // Orange color for markers
            strokeColors: "#fff",
            strokeWidth: 2,
            hover: {
              size: 8,
            },
          },
          tooltip: {
          theme: "light", // You can choose 'dark' or 'light'
          marker: {
            fillColors: ["#FFAE20"], // Change tooltip marker color to #FFAE20
          },
        },
          xaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
            ], // X-axis categories
          },
          /*yaxis: {
            title: {
              text: "Cost",
            },
          },
          */
          grid: {
            borderColor: "#e7e7e7",
            row: {
              colors: ["#f3f3f3", "transparent"], // Alternating row colors
              opacity: 0.5,
            },
          },
        },
      };
    },
  };
  </script>
  
  <style>
  /* Add custom styling if needed */
  </style>
  